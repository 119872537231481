export class ConsumerCartPaymentDisplayEvent extends Event {
    static NAME = "consumer:cart-payment-display";

    constructor(readonly details: { bookingId: string }) {
        super(ConsumerCartPaymentDisplayEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
